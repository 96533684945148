import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Select } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select"
    }}>{`Select`}</h1>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Select} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Select\n  value=\"\"\n  placeholder=\"Select one\"\n  options={[\'Apple\', \'Banana\', \'Candy\']}\n/>\n<Select\n  value=\"\"\n  placeholder=\"Select one\"\n  options={[\'Apple\', \'Banana\', \'Candy\']}\n  fill\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Select,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Select value="" placeholder="Select one" options={['Apple', 'Banana', 'Candy']} mdxType="Select" />
  <Select value="" placeholder="Select one" options={['Apple', 'Banana', 'Candy']} fill mdxType="Select" />
    </Playground>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={2} __code={'<Select\n  value=\"Success\"\n  options={[\n    { label: \'Success\', value: \'Success\' },\n    { label: \'Fail\', value: \'Fail\' },\n  ]}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Select,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Select value="Success" options={[{
        label: 'Success',
        value: 'Success'
      }, {
        label: 'Fail',
        value: 'Fail'
      }]} mdxType="Select" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      